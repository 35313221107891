<template>
  <b-container>
    <div>
      <b-row><b-col><h1>Dashboard</h1></b-col></b-row>
    </div>
    <div v-if="autorizado === true">
      <b-tabs content-class="mt-3" class="tabGramos" justified>
        <b-tab title="Performance general" active>
          <b-row class="justify-content-center">
            <b-col lg="6" offset-lg="6">
              <div class="d-flex flex-row justify-content-end mb-2">
                <div class="px-2"><b-select v-model="selectedDesdePerformance" :options="optionsDesdePerformance"></b-select></div>
                <div class="px-2"><b-select v-model="selectedHastaPerformance" :options="optionsHastaPerformance"></b-select></div>
                <div class="px-2"><b-select v-model="selectedTipoPerformance" :options="optionsTipoPerformance"></b-select></div>
                <div class="ps-2"><b-button class="rojoGramos float-right" v-on:click="refreshDashboardPerformanceGeneral()"> <b-icon icon="arrow-clockwise" font-scale="1" :animation="animationPerformanceGeneral"></b-icon></b-button></div>
              </div>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col lg="4" class="cardGramos pt-3 px-3">
              <div v-if="typeof(performance.totalLostWeight)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/gramos-totales.png')"></b-img><span class="title">Gramos totales</span></b-col>
                </b-row>
                <b-row>
                  <b-col class="p-3" lg="8"><h1 style="text-align: center">{{ performance.totalLostWeight }}</h1></b-col>
                  <b-col class="p-3" lg="3">gramos bajados</b-col>
                </b-row>
                <b-row class="separator">
                  <b-col lg="7" class="p-3">
                    <b-row>
                      <b-col lg="6"><h3>{{ parseFloat(performance.avgTimeInGramos).toFixed(2).replace('.',',') }}</h3> días</b-col>
                      <b-col lg="6">tiempo promedio en GRAMOS</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="separatorLeft p-3" lg="5">
                    <b-row><b-col><h3>{{ performance.totalUsers }}</h3></b-col></b-row>
                    <b-row><b-col>usuarios</b-col></b-row>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="4" class="cardGramos  p-3">
              <div v-if="typeof(performance.dataPercent)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/carga-datos.png')"></b-img><span class="title">Carga de datos (%)</span></b-col>
                </b-row>
                <b-row style="padding-top: 35px;">
                  <b-col lg="7" class="graph-container"><vue-ellipse-progress :progress="performance.dataPercent" :size=100 fontSize="2rem" color="#4e833c" thickness="10%"  /></b-col>
                  <b-col lg="5">de las personas cargan los datos diariamente</b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="4" class="cardGramos p-3">
              <div v-if="typeof(performance.audioPercent)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/audios.png')"></b-img><span class="title">Audios (%)</span></b-col>
                </b-row>
                <b-row style="padding-top: 35px;">
                  <b-col lg="7" class="graph-container"><vue-ellipse-progress :progress="performance.audioPercent" :size=100 fontSize="2rem" color="#4e833c" thickness="10%" /></b-col>
                  <b-col lg="5">de las personas escuchan el audio diariamente</b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col lg="4" class="cardGramos p-3">
              <div v-if="typeof(performance.foodPercent)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/comidas-sanas.png')"></b-img><span class="title">Comidas sanas (%)</span></b-col>
                </b-row>
                <b-row>
                  <b-col lg="7" class="p-3 graph-container"><vue-ellipse-progress :progress="performance.foodPercent" :size=100 fontSize="2rem" color="#4e833c" thickness="10%" /></b-col>
                  <b-col class="p-3" lg="5">de las personas comen sano diariamente</b-col>
                </b-row>
                <b-row class="separator">
                  <b-col lg="4" class="p-3" >Promedio de comidas sanas por día</b-col>
                  <b-col lg="8" class="p-3" >
                    <b-row>
                      <b-col lg="10" style="padding-top: 35px;">
                        <div class="range">
                          <input type="range" min="0" max="5"
                                 ref="imputRange"
                                 v-model="sliderVal" @change="change($event)"
                                 :style="styleSliderVal"
                                 disabled
                          />

                          <div class="ticks">
                            <span v-bind:class="showTick(0)" class="tick tick0" ></span>
                            <span v-bind:class="showTick(1)" class="tick tick1" ></span>
                            <span v-bind:class="showTick(2)" class="tick tick2" ></span>
                            <span v-bind:class="showTick(3)" class="tick tick3" ></span>
                            <span v-bind:class="showTick(4)" class="tick tick4" ></span>
                            <span v-bind:class="showTick(5)" class="tick tick5" ></span>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="1" style="padding-top: 22px;">
                        <h3>{{performance.avgFoodPerDay}}</h3>
                      </b-col>
                    </b-row>
<!--                    <h3>{{parseFloat(performance.avgFoodPerDay).toFixed(2).replace('.',',')}}</h3>-->
<!--                    <b-img width="250px" :src="require('../assets/20210724promediocomidassanas.png')"></b-img>-->

                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="4" class="cardGramos p-3">
              <div v-if="typeof(performance.sportPercent)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/ejercicio.png')"></b-img><span class="title">Ejercicio (%)</span></b-col>
                </b-row>
                <b-row>
                  <b-col lg="7" class="p-3 graph-container"><vue-ellipse-progress :progress="performance.sportPercent" :size=100 fontSize="2rem" color="#4e833c" thickness="10%" /></b-col>
                  <b-col lg="5" class="p-3">de las personas realizan ejercicio diariamente</b-col>
                </b-row>
                <b-row class="separator">
                  <b-col class="p-3">Tiempo promedio por sesión de ejercicio</b-col>
                  <b-col class="p-3"><h3>{{ parseFloat(performance.avgSportPerSession).toFixed(2).replace('.',',')}} min.</h3></b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="4" class="cardGramos p-3">
              <div v-if="typeof(videos)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/videos.png')"></b-img><span class="title">Videos</span></b-col>
                </b-row>
                <b-row class="px-2">
                  <b-col lg="4"></b-col>
                  <b-col lg="4" class="grisGramos cellCard text-white fw-bold" style="border-top-left-radius: 10px;">VISUALIZACIONES</b-col>
                  <b-col lg="4" class="grisGramos cellCard text-white fw-bold" style="border-top-right-radius: 10px;">TIEMPO</b-col>
                </b-row>
                <b-row class="px-2">
                  <b-col lg="4" class="amarilloGramos cellCard text-white fw-bold" style="border-top-left-radius: 10px;">GESTIÓN</b-col>
                  <b-col lg="4" class="cellCard value">{{ videos.gestion.visualizaciones}}</b-col>
                  <b-col lg="4" class="cellCard value">{{ videos.gestion.tiempo }}</b-col>
                </b-row>
                <b-row class="px-2">
                  <b-col lg="4" class="naranjaGramos cellCard text-white fw-bold">MOVIMIENTO</b-col>
                  <b-col lg="4" class="cellCard value">{{ videos.movimiento.visualizaciones }}</b-col>
                  <b-col lg="4" class="cellCard value">{{ videos.movimiento.tiempo }}</b-col>
                </b-row>
                <b-row class="px-2">
                  <b-col lg="4" class="racionalidadAlimentaria cellCard text-white fw-bold">RACIONALIDAD ALIMENTARIA</b-col>
                  <b-col lg="4" class="cellCard value">{{ videos.racionalidad.visualizaciones }}</b-col>
                  <b-col lg="4" class="cellCard value">{{ videos.racionalidad.tiempo }}</b-col>
                </b-row>
                <b-row class="px-2">
                  <b-col lg="4" class="verdeGramos cellCard text-white fw-bold" style="border-bottom-left-radius: 10px;">SEGUIMIENTO</b-col>
                  <b-col lg="4" class="cellCard value">{{ videos.seguimiento.visualizaciones }}</b-col>
                  <b-col lg="4" class="cellCard value" style="border-bottom-right-radius: 10px;">{{ videos.seguimiento.tiempo }}</b-col>
                </b-row>

              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Indicadores de Usuarios">
          <b-row class="justify-content-center">
            <b-col lg="6" offset-lg="6">
              <div class="d-flex flex-row justify-content-end mb-2">
                <div class="px-2">
                  <b-select v-model="selectedDesdeIndicadoresUsuarios" :options="optionsDesdeIndicadoresUsuarios"></b-select>
                </div>
                <div class="px-2">
                  <b-select v-model="selectedHastaIndicadoresUsuarios" :options="optionsHastaIndicadoresUsuarios"></b-select>
                </div>
                <div class="px-2">
                  <b-select v-model="selectedTipoIndicadoresUsuarios" :options="optionsTipoIndicadoresUsuarios"></b-select>
                </div>
                <div class="ps-2">
                  <b-button class="rojoGramos" v-on:click="refreshDashboardIndicadoresUsuarios()"> <b-icon icon="arrow-clockwise" font-scale="1" :animation="animationIndicadoresUsuarios"></b-icon></b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col lg="6" class="cardGramos p-3">
              <div v-if="typeof(performance.totalLostWeight)!='undefined'">
                <b-row>
                  <b-col><span class="title">Cantidad de Usuarios por Tipo</span></b-col>
                </b-row>
                <b-row>
                  <div id="chart">
                    <apexchart type="bar" height="350" :options="usuariosPorTipoChartOptions" :series="usuariosPorTipoSeries"></apexchart>
                  </div>
                </b-row>
              </div>
            </b-col>
            <b-col lg="6" class="cardGramos  p-3">
              <div v-if="typeof (performance.dataPercent)!='undefined'">
                <b-row>
                  <b-col><span class="title">Cantidad de Usuarios por Sexo</span></b-col>
                </b-row>
                <b-row>
                  <b-col class="pt-5">
                    <mdb-container>
                      <mdb-pie-chart
                          datalabels
                          :data="usuariosSexoChartData"
                          :options="usuariosSexoChartOptions"
                          :width="600"
                          :height="300"
                      />
                    </mdb-container>
                  </b-col>
                </b-row>

              </div>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col lg="6" class="cardGramos p-3">
              <div v-if="typeof(performance.totalLostWeight)!='undefined'">
                <b-row>
                  <b-col><span class="title">Cantidad de Usuarios por País</span></b-col>
                </b-row>
                <b-row>
                  <b-col class="pt-5">
                    <div v-for="country in user.totalPerCountry" v-bind:key="country.country" class="row mb-1">
                      <div v-if="country.country == 'AR'" class="col-sm-2">Argentina</div>
                      <div v-else-if="country.country == 'UY'" class="col-sm-2">Uruguay</div>
                      <div v-else class="col-sm-2">{{ country.country }}</div>
                      <div class="col-sm-10 pt-1">
                        <b-progress :value="country.total" variant="success" :precision="2" show-progress></b-progress>
                      </div>
                    </div>
                  </b-col>
                </b-row>

              </div>
            </b-col>
            <b-col lg="6" class="cardGramos  p-3">
              <div v-if="typeof (performance.dataPercent) !='undefined'">
                <b-row>
                  <b-col><span class="title">Cantidad de Usuarios por Edad</span></b-col>
                </b-row>
                <b-row>
                  <b-col class="pt-5">
                    <mdb-container>
                      <mdb-pie-chart
                          datalabels
                          :data="usuariosEdadChartData"
                          :options="usuariosEdadChartOptions"
                          :width="600"
                          :height="300"
                      />
                    </mdb-container>
                  </b-col>
                </b-row>

              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Control del negocio">
          <b-row class="justify-content-center">
            <b-col lg="6" offset-lg="6">
              <div class="d-flex flex-row justify-content-end mb-2">
                <div class="px-2">
                  <b-select class="float-right" v-model="selectedTipoControlNegocio" :options="optionsTipoControlNegocio"></b-select>
                </div>
                <div class="ps-2">
                  <b-button class="rojoGramos float-right" v-on:click="refreshDashboardControlNegocio()"><b-icon icon="arrow-clockwise" font-scale="1" :animation="animationControlNegocio"></b-icon></b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col lg="4" class="cardGramos p-3">
              <div v-if="typeof(business.revenue)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/coins.png')"></b-img> <span class="title">Revenue este mes</span> </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" class="justify-content-center" style="text-align: center"><h1>{{ business.revenue.total }}</h1></b-col>
                  <b-col lg="12" class="justify-content-center" style="text-align: center">{{ business.revenue.percentage }}</b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="4" class="cardGramos  p-3">
              <div v-if="typeof(business.subscriptions)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/clipboard.png')"></b-img> <span class="title">Distribución de planes</span></b-col>
                </b-row>
                <b-row>
                  <div v-for="plan in business.subscriptions" v-bind:key="plan.type" class="row mb-1">
                    <div class="col-sm-3">{{ plan.type }}</div>
                    <div class="col-sm-9 pt-1">
                      <b-progress :value="plan.value" variant="success" :precision="2" show-progress></b-progress>
                    </div>
                  </div>
                </b-row>
              </div>
            </b-col>
            <b-col lg="4" class="cardGramos p-3">
              <div v-if="typeof(business.avgChurn)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/lost.png')" ></b-img> <span class="title">Churn rate (%)</span></b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col lg="7" class="graph-container"><vue-ellipse-progress :progress="business.avgChurn" :size=100 fontSize="2rem" color="#4e833c" thickness="10%" style="float:right;" /></b-col>
                  <b-col lg="5">bajas en el padrón mensual</b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col lg="4" class="cardGramos p-3">
              <div v-if="typeof(business.duePayments)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/business-and-finance.png')"></b-img> <span class="title">Pagos</span></b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" style="text-align: right"><h1>{{ business.duePayments }}</h1></b-col>
                  <b-col lg="5">pagos a vencer este mes</b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" style="text-align: right"><h1>{{ business.overduePayments }}</h1></b-col>
                  <b-col lg="5">pagos vencidos</b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="4" class="cardGramos p-3">
              <div v-if="typeof(business.avgTotalPaying)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/sigma.png')"></b-img> <span class="title">Total del padrón acumulado (%)</span></b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col lg="7" class="graph-container"><vue-ellipse-progress :progress="business.avgTotalPaying" :size=100 fontSize="2rem" color="#4e833c" thickness="10%" style="float:right;" /></b-col>
                  <b-col lg="5">del padrón con pagos al día</b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="4" class="cardGramos p-3">
              <div v-if="typeof(business.avgRejectedPayments)!='undefined'">
                <b-row>
                  <b-col><b-img :src="require('../assets/rejected.png')"></b-img> <span class="title">Rechazos (%)</span></b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col lg="7" class="graph-container"><vue-ellipse-progress :progress="business.avgRejectedPayments" :size=100 fontSize="2rem" color="#4e833c" thickness="10%" style="float:right;" /></b-col>
                  <b-col lg="5">rechazados de tarjetas por sello o medio de pago</b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
    </div>
    <div v-else-if="autorizado===false">
      <b-row><b-col><p><strong>Acceso no autorizado. Debe <a v-bind:href="'/'">iniciar sesión</a>. </strong></p></b-col></b-row>
    </div>

  </b-container>
</template>

<script>
import axios from "axios";
import { mdbPieChart, mdbContainer } from "mdbvue";
import auth from "../logic/auth";

export default {
  components: {
    mdbPieChart,
    mdbContainer,
  },
  data() {
    return {
      autorizado: null,
      accessToken: '',
      selectedDesdePerformance: '',
      optionsDesdePerformance: [],
      selectedHastaPerformance: '',
      optionsHastaPerformance: [],
      selectedTipoPerformance: '',
      optionsTipoPerformance: [],
      animationPerformanceGeneral: '',
      selectedDesdeIndicadoresUsuarios: '',
      optionsDesdeIndicadoresUsuarios: [],
      selectedHastaIndicadoresUsuarios: '',
      optionsHastaIndicadoresUsuarios: [],
      selectedTipoIndicadoresUsuarios: '',
      optionsTipoIndicadoresUsuarios: [],
      animationIndicadoresUsuarios: '',
      selectedTipoControlNegocio: '',
      optionsTipoControlNegocio: [],
      animationControlNegocio: '',

      usuariosPorTipoSeries: [],
      usuariosPorTipoChartOptions: {},
      usuariosPorTipoChartCategories: [],


      usuariosSexoChartData: {
      },
      usuariosSexoChartOptions: {
      },
      usuariosEdadChartData: {},
      usuariosEdadChartOptions: {},

      performance: {

      },
      sliderVal: 0,
      porcentageSliderVal: 0,
      styleSliderVal: '',
      user: {
        totalPerType: [],
        totalPerGender: [],
        totalPerCountry: [],
        totalPerAge: []

      },
      business: {
        revenue: {},
        subscriptions: [],
        avgChurn: 0,
        duePayments: 0,
        overduePayments: 0,
        avgTotalPaying: 0,
        avgRejectedPayments: 0
      },



      carga_datos:0,
      audios:0,
      comidas_sanas: {
        porcentaje: 0,
        promedio: 0
      },
      ejercicio: {
        porcentaje: 0,
        promedio: 0
      },
      videos:{
        gestion:{
          visualizaciones: 0,
          tiempo: 0
        },
        movimiento:{
          visualizaciones: 0,
          tiempo: 0
        },
        racionalidad:{
          visualizaciones: 0,
          tiempo: 0
        },
        seguimiento:{
          visualizaciones: 0,
          tiempo: 0
        }
      },
      indicadores_usuarios: 0,
      control_negocio:0
    };
  },
  methods:{
    refreshDashboard: function(url, tab = ''){
      axios
          .get(url, {
            headers: {
              'Authorization': 'Bearer ' + this.accessToken
            }}
          )
          .then(response => {
            // console.log(response)
            this.loadDashboard(response, tab)
          })
    },

    refreshDashboardPerformanceGeneral: function(){
      this.animationPerformanceGeneral = 'spin'
      let url = process.env.VUE_APP_GRAMOSAPIHOST+'admin/contact?startDate='+this.selectedDesdePerformance+'&endDate='+this.selectedHastaPerformance+(this.selectedTipoPerformance?'&type='+this.selectedTipoPerformance:'')
      this.refreshDashboard(url, 'performance')
      setTimeout(() => this.animationPerformanceGeneral = '', 2000);

    },
    refreshDashboardIndicadoresUsuarios: function(){
      this.animationIndicadoresUsuarios = 'spin'
      let url = process.env.VUE_APP_GRAMOSAPIHOST+'admin/contact?startDate='+this.selectedDesdeIndicadoresUsuarios+'&endDate='+this.selectedHastaIndicadoresUsuarios+(this.selectedTipoIndicadoresUsuarios?'&type='+this.selectedTipoIndicadoresUsuarios:'')
      this.refreshDashboard(url, 'indicadoresUsuarios')
      setTimeout(() => this.animationIndicadoresUsuarios = '', 2000);
    },
    refreshDashboardControlNegocio: function(){
      this.animationControlNegocio = 'spin'
      let url = process.env.VUE_APP_GRAMOSAPIHOST+'admin/contact?'+(this.selectedTipoControlNegocio?'&type='+this.selectedTipoControlNegocio:'')
      this.refreshDashboard(url, 'controlNegocio')
      setTimeout(() => this.animationControlNegocio = '', 2000);
    },
    loadDashboard: function(response, tab = ''){
      if (tab == '' || tab == 'performance'){
        this.performance.totalLostWeight = response.data.performance.totalLostWeight?response.data.performance.totalLostWeight:0
        this.performance.avgTimeInGramos = response.data.performance.avgTimeInGramos>0?response.data.performance.avgTimeInGramos:0
        this.performance.totalUsers = response.data.performance.totalUsers
        this.performance.dataPercent = response.data.performance.dataPercent
        this.performance.audioPercent = response.data.performance.audioPercent
        this.performance.foodPercent = response.data.performance.foodPercent
        this.performance.avgFoodPerDay = Math.round(response.data.performance.avgFoodPerDay)
        this.sliderVal= this.performance.avgFoodPerDay
        this.porcentageSliderVal = this.sliderVal * 100 / 5
        this.styleSliderVal = 'background: linear-gradient(to right, #E33613 0%, #E33613 '+this.porcentageSliderVal+'%, #FBE4D7 '+this.porcentageSliderVal+'%, #FBE4D7 100%);'

        this.performance.sportPercent = response.data.performance.sportPercent
        this.performance.avgSportPerSession = response.data.performance.avgSportPerSession>0?response.data.performance.avgSportPerSession:0
        this.performance.watchedVideos = response.data.performance.watchedVideos
        this.videos.gestion.visualizaciones = response.data.performance.watchedVideos.gestion.visualizaciones
        this.videos.gestion.tiempo = response.data.performance.watchedVideos.gestion.tiempo
        this.videos.movimiento.visualizaciones = response.data.performance.watchedVideos.movimiento.visualizaciones
        this.videos.movimiento.tiempo = response.data.performance.watchedVideos.movimiento.tiempo
        this.videos.racionalidad.visualizaciones = response.data.performance.watchedVideos.racionalidad.visualizaciones
        this.videos.racionalidad.tiempo = response.data.performance.watchedVideos.racionalidad.tiempo
        this.videos.seguimiento.visualizaciones = response.data.performance.watchedVideos.seguimiento.visualizaciones
        this.videos.seguimiento.tiempo = response.data.performance.watchedVideos.seguimiento.tiempo
      }

      if (tab == '' || tab == 'indicadoresUsuarios'){
        this.user.totalPerType = response.data.user.totalPerType
        this.user.totalPerGender = response.data.user.totalPerGender
        // for (let i=0; i<response.data.user.totalPerGender.length; i++){
        //
        // }
        this.user.totalPerCountry = response.data.user.totalPerCountry
        this.user.totalPerAge = response.data.user.totalPerAge

        this.usuariosPorTipoChartCategories = response.data.user.totalPerType.months
        this.usuariosPorTipoSeries = []
        for (let i=0; i<response.data.user.totalPerType.types.length; i++){
          // console.log('types '+i)
          this.usuariosPorTipoSeries.push({name:response.data.user.totalPerType.types[i].label, data:response.data.user.totalPerType.types[i].totales})
          // console.log(response.data.user.totalPerType.types[i])
        }

        this.usuariosPorTipoChartOptions = {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: true
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10
            },
          },
          xaxis: {
            type: 'string',
            categories: this.usuariosPorTipoChartCategories,
          },
          legend: {
            position: 'right',
            offsetY: 40
          },
          fill: {
            opacity: 1,
          },
          colors: ['#008ffb', '#00e396','#feb019', '#ff4560', '#775dd0', '#4E833C', '#4D4D4D']
        }



        // this.usuariosSexoChartData.datasets.data = [45, 15, 0]
        this.usuariosSexoChartData= {
          labels: [],
          datasets: [
            {
              // data: [this.usuarios.sexo.M, this.usuarios.sexo.F, this.usuarios.sexo.O],
              data: [],
              backgroundColor: [
                '#008ffb',
                '#00e396',
                '#feb019',
                '#ff4560',

              ],
              hoverBackgroundColor: [
                'rgba(0,143,251,0.63)',
                'rgba(0,227,150,0.63)',
                'rgba(254,176,25,0.63)',
                'rgba(255,69,96,0.63)',

              ]
            }
          ]
        }

        for (let i=0; i<response.data.user.totalPerGender.length; i++){
          this.usuariosSexoChartData.labels.push(response.data.user.totalPerGender[i].description+' - '+response.data.user.totalPerGender[i].total+' usuarios')
          this.usuariosSexoChartData.datasets[0].data.push(response.data.user.totalPerGender[i].total)
          // console.log(response.data.user.totalPerAge[i].age)
        }

        this.usuariosSexoChartOptions= {
          responsive: false,
          maintainAspectRatio: false,
          legend: {
            position: 'right'
          },
          plugins: {
            datalabels: {
              color: "white",
              align: "center",
              font: {
                size: 16
              },
              formatter: value => {
                const [dataset] = this.usuariosSexoChartData.datasets;
                const setValue = dataset.data.reduce((a, b) => a + b);

                return `${Math.round((value / setValue) * 100)}%`;
              }
            }
          }
        }

        this.usuariosEdadChartData= {
          labels: [],
          datasets: [
            {
              // data: [this.usuarios.sexo.M, this.usuarios.sexo.F, this.usuarios.sexo.O],
              data: [],
              backgroundColor: [
                '#008ffb',
                '#00e396',
                '#feb019',
                '#ff4560',
                '#775dd0',
                '#4E833C',
                '#4D4D4D'

              ],
              hoverBackgroundColor: [
                'rgba(0,143,251,0.63)',
                'rgba(0,227,150,0.63)',
                'rgba(254,176,25,0.63)',
                'rgba(255,69,96,0.63)',
                'rgba(119,93,208,0.63)',
                'rgba(78,131,60,0.63)',
                'rgba(77,77,77,0.63)'

              ]
            }
          ]
        }

        for (let i=0; i<response.data.user.totalPerAge.length; i++){
          this.usuariosEdadChartData.labels.push(response.data.user.totalPerAge[i].age+' - '+response.data.user.totalPerAge[i].total+' usuarios')
          this.usuariosEdadChartData.datasets[0].data.push(response.data.user.totalPerAge[i].total)
          // console.log(response.data.user.totalPerAge[i].age)
        }
        // console.log(this.usuariosEdadChartData)

        this.usuariosEdadChartOptions= {
          responsive: false,
          maintainAspectRatio: false,
          legend: {
            position: 'right'
          },
          plugins: {
            datalabels: {
              color: "white",
              align: "center",
              font: {
                size: 16
              },
              formatter: value => {
                const [dataset] = this.usuariosEdadChartData.datasets;
                const setValue = dataset.data.reduce((a, b) => a + b);

                return `${Math.round((value / setValue) * 100)}%`;
              }
            }
          }
        }
      }

      if (tab == '' || tab == 'controlNegocio'){
        this.business.revenue.total = response.data.business.revenue.total
        this.business.revenue.percentage = response.data.business.revenue.percentage
        this.business.subscriptions = response.data.business.subscriptions
        this.business.avgChurn = response.data.business.avgChurn
        this.business.duePayments = response.data.business.duePayments
        this.business.overduePayments = response.data.business.overduePayments
        this.business.avgTotalPaying = response.data.business.avgTotalPaying
        this.business.avgRejectedPayments = response.data.business.avgRejectedPayments
      }

      this.$forceUpdate();

    },
    showTick(tick) {
      if (tick > this.sliderVal)
        return "showTick";
      else
        return "hideTick";
    }
  },
  mounted() {
    this.accessToken = auth.getUserLogged('accessToken');

    if (typeof(this.accessToken)!='undefined'){

      axios
          .get(process.env.VUE_APP_GRAMOSAPIHOST+'admin/dataFilter', {
            headers: {
              'Authorization': 'Bearer ' + this.accessToken
            }
          })
          .then(response =>{
            console.log(response)
            this.selectedDesdePerformance = response.data.filterStartDate.selected
            this.optionsDesdePerformance = response.data.filterStartDate.options
            this.selectedHastaPerformance = response.data.filterEndDate.selected
            this.optionsHastaPerformance = response.data.filterEndDate.options
            this.selectedTipoPerformance = response.data.filterProfilesTypes.selected
            this.optionsTipoPerformance = response.data.filterProfilesTypes.options

            this.selectedDesdeIndicadoresUsuarios = response.data.filterStartDate.selected
            this.optionsDesdeIndicadoresUsuarios = response.data.filterStartDate.options
            this.selectedHastaIndicadoresUsuarios = response.data.filterEndDate.selected
            this.optionsHastaIndicadoresUsuarios = response.data.filterEndDate.options
            this.selectedTipoIndicadoresUsuarios = response.data.filterProfilesTypes.selected
            this.optionsTipoIndicadoresUsuarios = response.data.filterProfilesTypes.options

            this.selectedTipoControlNegocio = response.data.filterProfilesTypes.selected
            this.optionsTipoControlNegocio = response.data.filterProfilesTypes.options
          })

      axios
          .get(process.env.VUE_APP_GRAMOSAPIHOST+'admin/contact',
              {
                headers: {
                  'Authorization': 'Bearer ' + this.accessToken
                }
              }
          )
          .then(response => {
            this.autorizado = true
            console.log(response)
            this.loadDashboard(response)

          })
          .catch(err => {
            this.autorizado = false
            auth.deleteUserLogged()
            console.log(err);
          });
    }
    else{
      this.autorizado = false
    }

  }
};

</script>
<style>


.racionalidadAlimentaria{
  background-color: #E33613;
}

</style>